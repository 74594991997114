import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="py-24 text-center mx-20 text-instagram-light">
      <h1 className="font-bold text-xl my-2">
        Privacy Policy for InsightsTool
      </h1>
      <p>Effective Date: 01/07/2024</p>
      <h2>1. Introduction</h2>
      <p>
        Welcome to InsightsTool, an application that accesses and utilizes
        public data provided by the Meta API. Your privacy is important to us.
        This Privacy Policy explains how we collect, use, and protect your
        information when you use our app.
      </p>

      <h2>2. Information We Collect</h2>
      <p>
        InsightsTool accesses and processes the following types of information:
      </p>
      <ul>
        <li>
          <strong>Public Data from Meta API:</strong> Our app collects and
          processes publicly available information from Facebook pages through
          the Meta API. This includes page names, IDs, about sections, fan
          counts, and other public data fields you request.
        </li>
      </ul>

      <h2>3. How We Use Information</h2>
      <p>We use the collected information to:</p>
      <ul>
        <li>
          Provide insights and analytics based on the public data accessed from
          the Meta API.
        </li>
        <li>Improve our app's functionality and user experience.</li>
      </ul>

      <h2>4. Data Sharing and Disclosure</h2>
      <p>
        We do not share or disclose your personal information to third parties
        except in the following cases:
      </p>
      <ul>
        <li>
          <strong>Compliance with Laws:</strong> We may disclose your
          information if required by law or in response to valid requests by
          public authorities (e.g., a court or a government agency).
        </li>
        <li>
          <strong>Business Transfers:</strong> In the event of a merger,
          acquisition, or sale of all or a portion of our assets, your
          information may be transferred as part of the transaction.
        </li>
      </ul>

      <h2>5. Data Security</h2>
      <p>
        We implement reasonable security measures to protect the information
        collected through our app. However, please note that no method of
        transmission over the Internet or electronic storage is 100% secure.
      </p>

      <h2>6. Your Choices</h2>
      <p>
        You can choose not to use our app if you do not agree with the data
        practices described in this Privacy Policy. You can also contact us to
        request access to, correction of, or deletion of your information.
      </p>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. The
        changes are effective immediately upon posting.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or our
        data practices, please contact us at:
      </p>
      <p>Email: contact@softwareosity.com</p>
      <p>Address: Goa,India</p>
    </div>
  );
};

export default PrivacyPolicy;
