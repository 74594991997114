import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import {
  setAddUserToTeamModal,
  setCreateProjectModal,
  setErrorModalStatus,
  setLoaderModalStatus,
} from "./ModalSlice";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import LoadingIcons from "react-loading-icons";
import { useAuthState } from "react-firebase-hooks/auth";
import { addUserProject, auth } from "../../app/firebase";
import { setCurrentSelectedProject } from "../Home/HomeSlice";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Add } from "../../assets/add.svg";
import { LoaderModal } from "./LoaderModal";
import axios from "axios";
import { ADDR } from "../../config/config";

const AddUserModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let isOpen = useAppSelector(
    (state) => state.modalSlice.addUserToTeamModal.status,
  );
  let name = useAppSelector(
    (state) => state.modalSlice.addUserToTeamModal.name,
  );
  let role = useAppSelector(
    (state) => state.modalSlice.addUserToTeamModal.role,
  );

  let email = useAppSelector(
    (state) => state.modalSlice.addUserToTeamModal.email,
  );

  let createdDate = useAppSelector(
    (state) => state.modalSlice.addUserToTeamModal.createdDate,
  );

  let createProjectModalObject = useAppSelector(
    (state) => state.modalSlice.createProjectModal,
  );

  const [newUsername, setNewUsername] = useState(""); // State for input field
  const [user, loading] = useAuthState(auth);

  let [nameTemp, setName] = useState("");
  let [roleTemp, setRole] = useState("Coming Soon");
  let [emailTemp, setEmail] = useState("");
  let [allUsers, setAllUsers] = useState([]);

  const closeModal = () => {
    dispatch(
      setAddUserToTeamModal({
        status: false,
        name: "",
        role: "",
        email: "",
        createdDate: new Date().toDateString(),
      }),
    );
  };

  const projectNameHandler = (e) => {
    let temp = { ...createProjectModalObject };
    temp.projectName = e.target.value;
    dispatch(setCreateProjectModal(temp));
  };

  const addUsernameHandler = () => {
    if (newUsername.trim() !== "") {
      let temp = { ...createProjectModalObject };
      temp.usernames = [...temp.usernames, newUsername];
      dispatch(setCreateProjectModal(temp));
      setNewUsername(""); // Clear the input after adding
    }
  };

  const nameHandler = (e) => {
    let value = e.target.value;

    setName(value);
  };

  const emailHandler = (e) => {
    let value = e.target.value;

    setEmail(value);
  };

  const addUserToTeam = async () => {
    console.log("ADD TEAM");
    //Get license - based on license allow to add certain number of team members;
    closeModal();
    if (user) {
      let email = user.email;
      let userID = user.uid;

      let licenseDetail = await axios
        .post(ADDR + "/getLicenseDetailOfUser", { email, userID })
        .then((response) => {
          return response;
        });

      console.log(licenseDetail.data[0]);

      let licenseDetailsFromDB = licenseDetail.data[0];

      if (licenseDetailsFromDB.planType === "standard") {
        console.log("standard");
        //No Team Users
        dispatch(
          setErrorModalStatus({
            status: true,
            message: "Upgrade To Premium And Above",
          }),
        );
      } else if (licenseDetailsFromDB.planType === "premium") {
        console.log("premium");
        //2 users
        //Get count of users first then add or show error
        let email = user.email;
        let userID = user.uid;
        let result = await axios
          .post(ADDR + "/getCountOfUsersAddedByUser", { email, userID })
          .then((response) => {
            console.log(response);
            return response.data[0];
          });
        const { "COUNT(*)": count } = result;

        console.log(count);

        if (count < 2) {
          //Add

          let name = nameTemp;
          let role = roleTemp;
          email = emailTemp;
          let addedByEmail = user.email;
          let addedByUID = user.uid;
          await axios
            .post(ADDR + "/addUserToTeam", {
              name,
              email,
              role,
              addedByEmail,
              addedByUID,
            })
            .then((response) => {
              console.log(response);
            });
        } else {
          //Dont Add
          dispatch(
            setErrorModalStatus({
              status: true,
              message: "Max Users Added - Upgrade Plan.",
            }),
          );
        }
      } else if (licenseDetailsFromDB.planType === "enterprise") {
        console.log("enterprise");
        //5 users
        //Get count of users first then add or show error
        let email = user.email;
        let userID = user.uid;
        let result = await axios
          .post(ADDR + "/getCountOfUsersAddedByUser", { email, userID })
          .then((response) => {
            console.log(response);
            return response.data[0];
          });
        const { "COUNT(*)": count } = result;

        console.log(count);

        if (count < 5) {
          //Add

          let name = nameTemp;
          let role = roleTemp;
          email = emailTemp;
          let addedByEmail = user.email;
          let addedByUID = user.uid;
          await axios
            .post(ADDR + "/addUserToTeam", {
              name,
              email,
              role,
              addedByEmail,
              addedByUID,
            })
            .then((response) => {
              console.log(response);
            });
        } else {
          //Dont Add
          dispatch(
            setErrorModalStatus({
              status: true,
              message: "Max Users Added.",
            }),
          );
        }
      }
    }
  };

  //   const createProjectFirebase = async () => {
  //     dispatch(
  //       setLoaderModalStatus({
  //         status: true,
  //         message: "Creating Project",
  //       }),
  //     );
  //     closeModal();
  //     let uid = user.uid;
  //     let projectNameTemp = projectName;
  //     let userList = [...new Set(usernameList)];
  //     let cDate = createdDate;
  //     let lModified = lastModified;
  //     let lUsed = lastUsed;
  //     let success = await addUserProject(
  //       uid,
  //       projectNameTemp,
  //       cDate,
  //       lModified,
  //       lUsed,
  //       userList,
  //     );
  //     if (success) {
  //       //Redirect to dashboard/Home
  //       //SET CURRENT PROJECT SOMEHOW

  //       dispatch(
  //         setCurrentSelectedProject({
  //           projectName: projectName,
  //           projectList: userList,
  //         }),
  //       );

  //       dispatch(
  //         setLoaderModalStatus({
  //           status: false,
  //           message: "Creating Project",
  //         }),
  //       );

  //       navigate("/home", {
  //         state: {
  //           projectName: projectName,
  //           projectList: userList,
  //         },
  //       });
  //     } else {
  //       //Display error
  //       console.log("E");
  //     }
  //   };

  const getUsersAddedByAdmin = async (email, userID) => {
    let allUsers = await axios
      .post(ADDR + "/getUsersAddedByAdmin", { email, userID })
      .then((response) => {
        return response.data;
      });

    console.log(allUsers);
    setAllUsers(allUsers);
  };

  useEffect(() => {
    if (user) {
      getUsersAddedByAdmin(user.email, user.uid);
    }
  }, [user]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-50"
            leave="ease-in duration-200"
            leaveFrom="opacity-50"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-lightbgone dark:bg-darkbgtwo opacity-50" />
          </Transition.Child>

          <div className="fixed  inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full  border border-1 border-darkbgfour max-w-md transform overflow-hidden rounded-2xl bg-instagram-dark backdrop-blur-xl opacity-100 p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between">
                    <Dialog.Title
                      as="h3"
                      className="text-3xl text-left font-semibold text-darkbgone dark:text-lightbgone py-2"
                    >
                      Add User
                    </Dialog.Title>

                    <button
                      onClick={closeModal}
                      class="cursor-pointer rounded-full bg-grey dark:bg-lightbgtwo w-10 h-10 my-2 mx-4 flex justify-center items-center "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="w-6 h-6 dark:text-black text-white"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <div className="mt-2 flex flex-col justify-center">
                    <label className="ml-4 text-darkbgone dark:text-lightbgtwo font-semibold text-left">
                      Name
                    </label>

                    <input
                      placeholder="Enter Name"
                      value={nameTemp}
                      onChange={nameHandler}
                      className="w-full rounded-[12px] text-left pl-4 py-2 my-2 font-medium"
                    />

                    <label className="ml-4 text-darkbgone dark:text-lightbgtwo font-semibold text-left">
                      Email
                    </label>

                    <input
                      placeholder="Enter Email"
                      value={emailTemp}
                      onChange={emailHandler}
                      className="w-full rounded-[12px] text-left pl-4 py-2 my-2 font-medium"
                    />

                    <label className="ml-4 text-darkbgone dark:text-lightbgtwo font-semibold text-left">
                      Role
                    </label>

                    <input
                      placeholder="Enter Project Name"
                      value={"Coming Soon"}
                      //   onChange={projectNameHandler}
                      className="w-full rounded-[12px] text-left pl-4 py-2 my-2 font-medium"
                    />

                    <legend class="border-t border-grey w-full mt-2"></legend>

                    <div className="mt-4">
                      <button
                        onClick={addUserToTeam}
                        className="w-full cursor-pointer my-2 text-lightbgtwo bg-primary font-semibold rounded-[20px] text-lg px-5 py-4 text-center mr-3 md:mr-0 flex justify-center items-center"
                      >
                        Add User
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <LoaderModal />
    </>
  );
};

export default AddUserModal;
