import { useEffect, useState } from "react";
import PlansAndBilling from "./PlansAndBilling/PlansAndBilling";
import Team from "./Team/Team";
import { useAppDispatch } from "../../app/hooks";
import { setCSSForProjects, setCSSForSettings } from "../Modals/ModalSlice";
import { UserUnverifiedModal } from "../Modals/UserUnverifiedModal";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../app/firebase";

const Settings = () => {
  let dispatch = useAppDispatch();

  const [selectedTab, setSelectedTab] = useState("team");
  const [cssForTeam, setCSSForTeams] = useState("");
  const [cssForPlans, setCSSForPlans] = useState("");

  const [user, loading] = useAuthState(auth);

  const switchToTeams = () => {
    setSelectedTab("team");
    setCSSForTeams("font-bold text-darkbgone dark:text-lightbgtwo");
    setCSSForPlans("text-grey");
  };

  const switchToPlansAndBilling = () => {
    setSelectedTab("plansandbilling");
    setCSSForPlans("font-bold text-darkbgone dark:text-lightbgtwo");
    setCSSForTeams("text-grey");
  };

  useEffect(() => {
    switchToPlansAndBilling();

    dispatch(setCSSForProjects("text-grey"));
    dispatch(
      setCSSForSettings("font-bold text-darkbgone dark:text-lightbgtwo"),
    );
  }, []);

  useEffect(() => {
    if (loading) {
      // show loader (you can manage this by setting a state or directly within JSX)
      return; // Early return to prevent further execution
    }

    if (user) {
      //   if (projectList.length === 0) {
      //     getExistingProjectsList();
      //     getLicenseDetails(user.email, user.uid);
      //   } else {
      //     //Do nothing
      //   }
    } else {
      window.location.replace("/login");
    }
  }, [user, loading]);

  return (
    <div className="flex justify-center items-center mt-24 flex-col">
      <div className="my-4 flex flex-col justify-center w-fit">
        <div className="flex flex-col justify-between">
          <h1 className="text-4xl font-bold mt-5 mb-2 text-left">Settings</h1>
          <div className="flex gap-4">
            <p
              onClick={switchToTeams}
              className={
                `hover:text-darkbgone dark:hover:text-lightbgtwo cursor-pointer ` +
                cssForTeam
              }
            >
              Team
            </p>
            <p
              onClick={switchToPlansAndBilling}
              className={
                `hover:text-darkbgone dark:hover:text-lightbgtwo cursor-pointer ` +
                cssForPlans
              }
            >
              Plans & Billing
            </p>
          </div>

          <div class="border-t border-grey mt-1"></div>

          {/* If Billing */}

          {selectedTab === "team" && <Team />}

          {selectedTab === "plansandbilling" && <PlansAndBilling />}
        </div>
      </div>
    </div>
  );
};

export default Settings;
