import HomeSlice from "../Components/Home/HomeSlice";
import ModalSlice from "../Components/Modals/ModalSlice";
import ProjectsSlice from "../Components/Projects/ProjectsSlice";
const { configureStore } = require("@reduxjs/toolkit");

const store = configureStore({
  reducer: {
    homeSlice: HomeSlice,
    modalSlice: ModalSlice,
    projectSlice: ProjectsSlice,
  },
});

export default store;
