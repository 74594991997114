import React, { useState, useEffect } from "react";

const DarkModeToggle = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "dark") {
      setIsDarkMode(true);
      document.documentElement.classList.add("dark");
    }
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    if (!isDarkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  };

  return (
    // <button
    //   onClick={toggleDarkMode}
    //   className={`w-14 h-8 flex items-center rounded-full p-1 transition duration-300 ${
    //     isDarkMode ? "bg-darkbgthree" : "bg-lightbgtwo"
    //   }`}
    // >
    //   <div
    //     className={`w-6 h-6 rounded-full bg-darkbgtwo shadow-md transform transition-transform duration-300 ease-in-out ${
    //       isDarkMode
    //         ? "translate-x-6 bg-darkbgtwo"
    //         : "translate-x-0 bg-lightbgthree"
    //     }`}
    //   />
    // </button>

    <button
      onClick={toggleDarkMode}
      className={`w-14 h-8 flex items-center rounded-full p-1 transition duration-300 ${
        isDarkMode ? "bg-darkbgthree" : "bg-lightbgtwo"
      }`}
    >
      <div
        className={`w-6 h-6 rounded-full shadow-md flex items-center justify-center transform transition-transform duration-300 ease-in-out ${
          isDarkMode
            ? "translate-x-6 bg-darkbgtwo"
            : "translate-x-0 bg-lightbgthree"
        }`}
      >
        {isDarkMode ? (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_136_2050)">
              <path
                d="M12.1243 10.6573C8.04797 10.6573 5.438 8.10011 5.438 3.88781C5.438 2.88259 5.64206 1.72117 5.94137 1.0774C6.04173 0.85343 6.08333 0.683724 6.08333 0.554714C6.08333 0.286031 5.88697 0.0286865 5.53255 0.0286865C5.40336 0.0286865 5.19275 0.060205 4.97741 0.143051C1.99411 1.34659 0 4.43998 0 7.72213C0 12.294 3.69257 16 8.23964 16C11.6293 16 14.5283 13.9565 15.6299 11.2459C15.7251 11.0088 15.7591 10.7995 15.7591 10.6808C15.7591 10.3204 15.4875 10.1063 15.2141 10.1063C15.0663 10.1063 14.9116 10.1561 14.6952 10.2302C14.0943 10.4318 13.2424 10.6573 12.1243 10.6573Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_136_2050">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.89691 2.97333C8.3123 2.97333 8.65872 2.62295 8.65872 2.19809V0.77524C8.65872 0.352613 8.3123 0 7.89691 0C7.47903 0 7.1326 0.352613 7.1326 0.77524V2.19809C7.1326 2.62295 7.47903 2.97333 7.89691 2.97333ZM11.4044 4.46227C11.6962 4.75478 12.1893 4.75511 12.4855 4.45556L13.4833 3.44198C13.7725 3.15171 13.7703 2.6482 13.4766 2.34642C13.1874 2.05614 12.6895 2.06061 12.4003 2.35313L11.3978 3.3715C11.1086 3.66178 11.1108 4.16528 11.4044 4.46227ZM12.8561 7.99652C12.8561 8.41434 13.2073 8.7647 13.6227 8.7647H15.0295C15.4426 8.7647 15.7913 8.41434 15.7913 7.99652C15.7913 7.57641 15.4426 7.22125 15.0295 7.22125H13.6227C13.2073 7.22125 12.8561 7.57641 12.8561 7.99652ZM11.3978 11.5396C11.1086 11.837 11.1108 12.3335 11.4044 12.6282L12.4092 13.6491C12.6984 13.9394 13.1963 13.9276 13.4899 13.6354C13.7769 13.3403 13.7747 12.8416 13.4811 12.5468L12.4763 11.5329C12.1871 11.2404 11.694 11.2497 11.3978 11.5396ZM7.89691 13.0197C7.47903 13.0197 7.1326 13.3701 7.1326 13.7879V15.2155C7.1326 15.6356 7.47903 15.9882 7.89691 15.9882C8.3123 15.9882 8.65872 15.6356 8.65872 15.2155V13.7879C8.65872 13.3701 8.3123 13.0197 7.89691 13.0197ZM4.39353 11.5396C4.09513 11.2497 3.59728 11.2404 3.30805 11.5329L2.30806 12.542C2.0144 12.8368 2.0122 13.3355 2.29447 13.6306C2.58813 13.9228 3.09073 13.9346 3.37995 13.6443L4.37994 12.6282C4.6736 12.3335 4.67581 11.837 4.39353 11.5396ZM2.93521 7.99652C2.93521 7.57641 2.58181 7.22125 2.16646 7.22125H0.759589C0.346444 7.22125 0 7.57641 0 7.99652C0 8.41434 0.346444 8.7647 0.759589 8.7647H2.16646C2.58181 8.7647 2.93521 8.41434 2.93521 7.99652ZM4.37994 4.46227C4.6736 4.17008 4.67581 3.65698 4.39353 3.3715L3.39355 2.35313C3.10906 2.06541 2.60646 2.05614 2.31723 2.34642C2.02578 2.6482 2.02136 3.15171 2.30585 3.43719L3.30362 4.45556C3.59507 4.75031 4.08818 4.75478 4.37994 4.46227Z"
              fill="#101011"
            />
            <path
              d="M7.8977 11.74C9.94058 11.74 11.5993 10.0625 11.5993 7.99646C11.5993 5.93041 9.94058 4.24805 7.8977 4.24805C5.85489 4.24805 4.19141 5.93041 4.19141 7.99646C4.19141 10.0625 5.85489 11.74 7.8977 11.74ZM7.8977 10.2682C6.65331 10.2682 5.64667 9.25276 5.64667 7.99646C5.64667 6.73789 6.65331 5.71984 7.8977 5.71984C9.14215 5.71984 10.144 6.73789 10.144 7.99646C10.144 9.25276 9.14215 10.2682 7.8977 10.2682Z"
              fill="#101011"
            />
          </svg>
        )}
      </div>
    </button>
  );
};

export default DarkModeToggle;
