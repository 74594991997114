import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import {
  setCreateProjectModal,
  setErrorModalStatus,
  setLoaderModalStatus,
} from "./ModalSlice";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import LoadingIcons from "react-loading-icons";
import { useAuthState } from "react-firebase-hooks/auth";
import { addUserProject, auth } from "../../app/firebase";
import { setCurrentSelectedProject } from "../Home/HomeSlice";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Add } from "../../assets/add.svg";
import { LoaderModal } from "./LoaderModal";
import axios from "axios";
import { ADDR } from "../../config/config";

const CreateProjectModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let isOpen = useAppSelector(
    (state) => state.modalSlice.createProjectModal.status,
  );
  let projectName = useAppSelector(
    (state) => state.modalSlice.createProjectModal.projectName,
  );
  let createProjectModalObject = useAppSelector(
    (state) => state.modalSlice.createProjectModal,
  );

  let usernameList = useAppSelector(
    (state) => state.modalSlice.createProjectModal.usernames,
  );

  let createdDate = useAppSelector(
    (state) => state.modalSlice.createProjectModal.createdDate,
  );
  let lastModified = useAppSelector(
    (state) => state.modalSlice.createProjectModal.lastModified,
  );
  let lastUsed = useAppSelector(
    (state) => state.modalSlice.createProjectModal.lastUsed,
  );

  let userTypeR = useAppSelector((state) => state.homeSlice.userType.value);

  const [newUsername, setNewUsername] = useState(""); // State for input field
  const [user, loading] = useAuthState(auth);

  const closeModal = () => {
    dispatch(
      setCreateProjectModal({
        status: false,
        projectName: "",
        createdDate: new Date().toDateString(),
        lastUsed: new Date().toDateString(),
        lastModified: new Date().toDateString(),
        usernames: [],
      }),
    );
  };

  const projectNameHandler = (e) => {
    let temp = { ...createProjectModalObject };
    temp.projectName = e.target.value;
    dispatch(setCreateProjectModal(temp));
  };

  const addUsernameHandler = () => {
    if (newUsername.trim() !== "") {
      let temp = { ...createProjectModalObject };
      temp.usernames = [...temp.usernames, newUsername];
      dispatch(setCreateProjectModal(temp));
      setNewUsername(""); // Clear the input after adding
    }
  };

  const getExistingProjectsFromDB = async (uid) => {
    let arr = [];
    if (uid) {
      arr = await axios
        .post(ADDR + "/getProjectsOfAUser", { uid })
        .then((response) => {
          console.log("PROJECTOFUSER:", response);
          let parsedArray = JSON.parse(response.data[0].projects);
          return parsedArray;
        });
    }

    return arr;
  };

  const createProjectInDB = async () => {
    dispatch(
      setLoaderModalStatus({
        status: true,
        message: "Creating Project",
      }),
    );
    closeModal();
    let uid = user.uid;
    let projectNameTemp = projectName;
    let userList = [...new Set(usernameList)];
    let cDate = createdDate;
    let lModified = lastModified;
    let lUsed = lastUsed;

    if (uid) {
      if (userTypeR === "User") {
        await axios
          .post(ADDR + "/getAdminUIDByUserEmail", { email: user.email })
          .then((response) => {
            console.log("RES2:", response);
            uid = response.data[0].AddedByUserID;
            console.log(uid);
          });
      }

      try {
        //Fetch existing projects of this user
        let existingProjects = await getExistingProjectsFromDB(uid);
        console.log("EXISTINGPROJS:", existingProjects);
        if (!Array.isArray(existingProjects)) {
          existingProjects = [];
        }

        // Add the new project to the existing projects array
        existingProjects.push({
          projectName: projectNameTemp,
          usernameList: userList,
          lastModified: lModified,
          lastUsed: lUsed,
          createdDate: cDate,
        });

        console.log("===========");
        console.log(existingProjects);
        console.log("===========");

        let success = await axios
          .post(ADDR + "/addProjectToProjectList", { existingProjects, uid })
          .then((response) => {
            console.log(response);
            console.log("Added successfully");
            if (response.status === 200) {
              return true;
            }
          });
        if (success) {
          dispatch(
            setCurrentSelectedProject({
              projectName: projectName,
              projectList: userList,
            }),
          );

          dispatch(
            setLoaderModalStatus({
              status: false,
              message: "Creating Project",
            }),
          );

          navigate("/home", {
            state: {
              projectName: projectName,
              projectList: userList,
            },
          });
        } else {
          //Display error
          console.log("E");
        }
      } catch (e) {
        dispatch(
          setErrorModalStatus({
            status: true,
            message: "",
          }),
        );

        dispatch(
          setLoaderModalStatus({
            status: false,
            message: "Creating Project",
          }),
        );
      }
    }
  };

  const createProjectFirebase = async () => {
    dispatch(
      setLoaderModalStatus({
        status: true,
        message: "Creating Project",
      }),
    );
    closeModal();
    let uid = user.uid;
    let projectNameTemp = projectName;
    let userList = [...new Set(usernameList)];
    let cDate = createdDate;
    let lModified = lastModified;
    let lUsed = lastUsed;
    let success = await addUserProject(
      uid,
      projectNameTemp,
      cDate,
      lModified,
      lUsed,
      userList,
    );
    if (success) {
      //Redirect to dashboard/Home
      //SET CURRENT PROJECT SOMEHOW

      dispatch(
        setCurrentSelectedProject({
          projectName: projectName,
          projectList: userList,
        }),
      );

      dispatch(
        setLoaderModalStatus({
          status: false,
          message: "Creating Project",
        }),
      );

      navigate("/home", {
        state: {
          projectName: projectName,
          projectList: userList,
        },
      });
    } else {
      //Display error
      console.log("E");
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-50"
            leave="ease-in duration-200"
            leaveFrom="opacity-50"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-lightbgone dark:bg-darkbgtwo opacity-50" />
          </Transition.Child>

          <div className="fixed  inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full  border border-1 border-darkbgfour max-w-md transform overflow-hidden rounded-2xl bg-instagram-dark backdrop-blur-xl opacity-100 p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between">
                    <Dialog.Title
                      as="h3"
                      className="text-3xl text-left font-semibold text-darkbgone dark:text-lightbgone py-2"
                    >
                      Add New Project
                    </Dialog.Title>

                    <button
                      onClick={closeModal}
                      class="cursor-pointer rounded-full bg-grey dark:bg-lightbgtwo w-10 h-10 my-2 mx-4 flex justify-center items-center "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="w-6 h-6 dark:text-black text-white"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <div className="mt-2 flex flex-col justify-center">
                    <label className="ml-4 text-darkbgone dark:text-lightbgtwo font-semibold text-left">
                      Project Name
                    </label>

                    <input
                      placeholder="Enter Project Name"
                      value={projectName}
                      onChange={projectNameHandler}
                      className="w-full rounded-[12px] text-left pl-4 py-2 my-2 font-medium"
                    />

                    <div className="flex flex-col w-full">
                      <label className="ml-4 text-darkbgone dark:text-lightbgtwo font-semibold text-left">
                        Instagram Username
                      </label>
                      <div className="flex">
                        <input
                          placeholder="unbubble"
                          value={newUsername}
                          onChange={(e) => setNewUsername(e.target.value)}
                          className="w-5/6 rounded-[12px] text-left pl-4 py-2 my-2 font-medium"
                        />

                        <button
                          onClick={addUsernameHandler}
                          class="cursor-pointer rounded-full bg-lightbgtwo dark:bg-darkbgthree w-10 h-10 my-2 mx-4 flex justify-center items-center "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="w-6 h-6 dark:text-white text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 4v16m8-8H4"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>

                    <legend class="border-t border-grey w-full mt-2"></legend>

                    <div className="mt-4 w-full h-40 overflow-y-auto rounded-lg">
                      {usernameList.length > 0 ? (
                        <div className="flex grid grid-cols-2 w-fit">
                          {usernameList.map((username, index) => (
                            <div
                              key={index}
                              className="flex mx-1 my-1 bg-lightbgtwo dark:bg-darkbgfour rounded-[12px] w-fit"
                            >
                              <p className="my-1 ml-2 dark:text-lightbgtwo">
                                {" "}
                                {"@"}
                                {username}
                              </p>
                              <button
                                onClick={closeModal}
                                class="cursor-pointer rounded-full w-[16px] h-[16px] my-2 mx-2 flex justify-center items-center "
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  class="w-[16px] h-[16px] dark:text-white text-black"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M6 18L18 6M6 6l12 12"
                                  />
                                </svg>
                              </button>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p className="text-grey ml-2">
                          No usernames added yet.
                        </p>
                      )}
                    </div>

                    <div className="mt-4">
                      <button
                        onClick={createProjectInDB}
                        className="w-full cursor-pointer my-2 text-lightbgtwo bg-primary font-semibold rounded-[20px] text-lg px-5 py-4 text-center mr-3 md:mr-0 flex justify-center items-center"
                      >
                        Add Project
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <LoaderModal />
    </>
  );
};

export default CreateProjectModal;
