import React from "react";
import Logo512 from "../../assets/navlogo.png";
const NotSupported = () => {
  return (
    <div className="py-56 flex flex-col justify-center text-center mx-20 text-instagram-light">
      <img
        src={Logo512}
        className="invert w-auto h-auto justify-center my-2"
        alt="unBubble GmbH Logo"
      />

      <h1 className="font-bold text-4xl my-2">
        Resize Window Or Use On A Big Screen Device
      </h1>
      <p className="text-xl text-instagram-light font-bold my-4">
        This App Is Not Optimised For Smaller Screens{" "}
      </p>
    </div>
  );
};

export default NotSupported;
