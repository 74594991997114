import { createSlice } from "@reduxjs/toolkit";
import { setDateSelected } from "../Home/HomeSlice";

const initialState = {
  projectList: [],
  projectListBackup: [],
  isSortedByProjectNameAscending: true,
  isSortedByTotalAccountsAscending: true,
  isCreatedDateLatest: true,
  projectNameSelected: {
    status: false,
    css: "",
  },
  totalAccountsSelected: {
    css: "",
  },
  createdDateSelected: {
    css: "",
  },

  isName: true,

  nameSelected: {
    css: "",
  },
  AccountsSelected: {
    css: "",
  },

  DateSelected: {
    css: "",
  },
};

const ProjectsSlice = createSlice({
  name: "projectSlice",
  initialState,
  reducers: {
    setNameSelected: (state, action) => {
      state.nameSelected = action.payload;
    },

    setAccountsSelectedCSS: (state, action) => {
      state.AccountsSelected = action.payload;
    },

    setDateSelectedCSS: (state, action) => {
      state.DateSelected = action.payload;
    },

    setProjectList: (state, action) => {
      state.projectList = action.payload;
    },
    setProjectListBackup: (state, action) => {
      state.projectListBackup = action.payload;
    },
    setIsSortedByProjectNameAscending: (state, action) => {
      state.isSortedByProjectNameAscending = action.payload;
    },
    setIsSortedByAccountsNumberAscending: (state, action) => {
      state.isSortedByTotalAccountsAscending = action.payload;
    },
    setIsCreatedDateLatest: (state, action) => {
      state.isCreatedDateLatest = action.payload;
    },
    setProjectNameSelected: (state, action) => {
      state.projectNameSelected = action.payload;
    },
    setTotalAccountsSelected: (state, action) => {
      state.totalAccountsSelected = action.payload;
    },
    setCreatedDateSelected: (state, action) => {
      state.createdDateSelected = action.payload;
    },

    setIsName: (state, action) => {
      state.isName = action.payload;
    },
  },
});

export default ProjectsSlice.reducer;
export const {
  setProjectList,
  setProjectListBackup,
  setIsSortedByProjectNameAscending,
  setProjectNameSelected,
  setTotalAccountsSelected,
  setIsSortedByAccountsNumberAscending,
  setIsCreatedDateLatest,
  setCreatedDateSelected,
  setIsName,
  setNameSelected,
  setAccountsSelectedCSS,
  setDateSelectedCSS,
} = ProjectsSlice.actions;
