import React from "react";

const TermsOfService = () => {
  return (
    <div className="py-24 text-center mx-20 text-instagram-light">
      <h1 className="font-bold text-xl my-2">
        Terms of Service for InsightsTool
      </h1>
      <p>Effective Date: 01/07/2024</p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing and using InsightsTool (“the App”), you agree to comply
        with and be bound by these Terms of Service (“Terms”). If you do not
        agree with these Terms, please do not use the App.
      </p>

      <h2>2. Description of Service</h2>
      <p>
        InsightsTool provides users with insights and analytics based on public
        data from Facebook pages accessed through the Meta API. The data
        includes, but is not limited to, page names, IDs, about sections, and
        fan counts.
      </p>

      <h2>3. Eligibility</h2>
      <p>
        You must be at least 13 years old to use the App. By using the App, you
        represent and warrant that you meet this age requirement.
      </p>

      <h2>4. User Responsibilities</h2>
      <p>When using the App, you agree to:</p>
      <ul>
        <li>
          Use the App in compliance with all applicable laws and regulations.
        </li>
        <li>Not use the App for any unlawful or prohibited purpose.</li>
        <li>Not interfere with or disrupt the App’s operation or security.</li>
        <li>
          Not attempt to gain unauthorized access to any part of the App or its
          systems.
        </li>
      </ul>

      <h2>5. Data and Privacy</h2>
      <p>
        Our Privacy Policy outlines how we collect, use, and protect your
        information when you use the App. By using the App, you consent to the
        data practices described in our Privacy Policy.
      </p>

      <h2>6. Intellectual Property</h2>
      <p>
        All content, features, and functionality of the App, including but not
        limited to text, graphics, logos, and software, are the exclusive
        property of [Your Company Name] or its licensors and are protected by
        intellectual property laws.
      </p>

      <h2>7. Termination</h2>
      <p>
        We reserve the right to terminate or suspend your access to the App at
        our sole discretion, without notice, for conduct that we believe
        violates these Terms or is harmful to other users of the App, us, or
        third parties, or for any other reason.
      </p>

      <h2>8. Disclaimer of Warranties</h2>
      <p>
        The App is provided on an “as is” and “as available” basis. We make no
        warranties, expressed or implied, about the App’s operation or the
        information, content, or materials included in the App. To the fullest
        extent permitted by law, we disclaim all warranties, including but not
        limited to implied warranties of merchantability and fitness for a
        particular purpose.
      </p>

      <h2>9. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by law, [Your Company Name] shall not be
        liable for any direct, indirect, incidental, special, or consequential
        damages arising out of or in connection with your use of the App, even
        if advised of the possibility of such damages.
      </p>

      <h2>10. Indemnification</h2>
      <p>
        You agree to indemnify, defend, and hold harmless [Your Company Name],
        its officers, directors, employees, and agents from and against any
        claims, liabilities, damages, losses, and expenses, including reasonable
        attorneys’ fees, arising out of or in any way connected with your use of
        the App or violation of these Terms.
      </p>

      <h2>11. Governing Law</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the
        laws of [Your Country/State], without regard to its conflict of law
        principles.
      </p>

      <h2>12. Changes to the Terms</h2>
      <p>
        We reserve the right to modify these Terms at any time. Any changes will
        be effective immediately upon posting the updated Terms on the App. Your
        continued use of the App following the posting of changes constitutes
        your acceptance of such changes.
      </p>

      <h2>13. Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us at:</p>
      <p>Email: contact@softwareosity.com</p>
      <p>Address: Goa,India</p>
    </div>
  );
};

export default TermsOfService;
