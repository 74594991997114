import React from "react";
import Logo512 from "../../assets/navlogo.png";

const NotFound = () => {
  return (
    <div className="flex flex-col justify-center py-24 text-center mx-20 text-instagram-light">
      <img
        src={Logo512}
        className="invert w-auto h-auto justify-center my-2"
        alt="unBubble GmbH Logo"
      />
      <h1 className="font-bold text-4xl my-2">404 - Page Not Found</h1>
      <p className="text-xl my-4">
        Oops! The page you're looking for doesn't exist.
      </p>
      <p className="my-4">
        It looks like the page you are trying to access is no longer available
        or has been moved.
      </p>
      <a href="/" className="text-blue-500 underline">
        Go back to the homepage
      </a>
    </div>
  );
};

export default NotFound;
