import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ReactComponent as ListCheck } from "../../../assets/v2/ListTick.svg";
import {
  setAddUserToTeamModal,
  setDeleteConfirmationStatus,
  setEditProjectModal,
  setPaymentModal,
  setPlanExpiredModal,
  setUserUnverifiedModal,
} from "../../Modals/ModalSlice";
import PaymentModal from "../../Modals/PaymentModal";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../app/firebase";
import axios from "axios";
import { ADDR } from "../../../config/config";
import { ReactComponent as DeleteIcon } from "../../../assets/v2/trash.svg";
import { ReactComponent as EditIcon } from "../../../assets/v2/edit.svg";
import AddUserModal from "../../Modals/AddUserModal";
import { ErrorModal } from "../../Modals/ErrorModal";
import { UserUnverifiedModal } from "../../Modals/UserUnverifiedModal";
import { PlanExpiredModal } from "../../Modals/planExpiredModal";

const Team = () => {
  const dispatch = useAppDispatch();
  const [user, loading] = useAuthState(auth);
  let [plan, setPlan] = useState();
  let [validityDate, setValidityDate] = useState();
  let [planStatus, setPlanStatus] = useState();

  let mediaData = useAppSelector((state) => state.homeSlice.mediaData);

  let fullName = useAppSelector((state) => state.homeSlice.nameOfUser.value);

  let projectList = useAppSelector((state) => state.projectSlice.projectList);

  let [allUsers, setAllUsers] = useState([]);
  let userTypeR = useAppSelector((state) => state.homeSlice.userType.value);
  const getLicenseDetails = async (email, userID) => {
    console.log(email);
    console.log(userID);
    let response = await axios
      .post(ADDR + "/getLicenseDataOfUser", { email, userID })
      .then((response) => {
        return response;
      });

    console.log("RES: ", response);
    let data = response.data;
    console.log(data);

    const latest = data.reduce((latestObj, currentObj) => {
      return new Date(currentObj.currentDate) > new Date(latestObj.currentDate)
        ? currentObj
        : latestObj;
    });

    console.log("Latest:", latest);

    setPlan(latest.planType);
    setValidityDate(latest.validTill);
    let status = latest.status;
    setPlanStatus(status);
  };

  const openHomeWithProject = (projectName, projectList) => {};

  const editEntry = (item) => {
    console.log(item);
    dispatch(
      setEditProjectModal({
        status: true,
        projectName: item.projectName,
        createdDate: item.createdDate,
        lastUsed: item.lastUsed,
        lastModified: item.lastModified,
        usernames: item.usernameList,
      }),
    );
  };
  const formatDateToText = (date) => {
    console.log("DATE:", date);
    if (!date) {
      console.error("Invalid date: The input is undefined or null");
      return "No date";
    }

    const dateVar = new Date(date);

    if (isNaN(dateVar)) {
      console.error("Invalid date: Unable to parse the input");
      return "No date";
    }

    const options = { day: "2-digit", month: "long", year: "numeric" };
    return new Intl.DateTimeFormat("en-GB", options).format(dateVar);
  };

  const capitalizeFirstLetter = (plan) => {
    if (plan !== undefined) {
      let text = plan.charAt(0).toUpperCase() + plan.slice(1);
      return text;
    } else {
      return plan;
    }
  };

  const deleteEntry = async (item) => {
    dispatch(
      setDeleteConfirmationStatus({
        status: true,
        projectName: item.projectName,
        entryUID: user.uid,
        entryItem: item,
      }),
    );

    console.log(user.uid, item);
    //  await deleteProjectEntry(user.uid,item);
  };

  const openAddUserModal = () => {
    dispatch(
      setAddUserToTeamModal({
        status: true,
        name: "",
        role: "",
        email: "",
        createdDate: new Date().toDateString(),
      }),
    );
  };

  const getUsersAddedByAdmin = async (email, userID) => {
    let allUsers = await axios
      .post(ADDR + "/getUsersAddedByAdmin", { email, userID })
      .then((response) => {
        return response.data;
      });

    console.log(allUsers);
    setAllUsers(allUsers);
  };

  useEffect(() => {
    if (user) {
      getLicenseDetails(user.email, user.uid);
      getUsersAddedByAdmin(user.email, user.uid);
    } else {
      console.log("Not logged in");
    }
  }, [user]);

  useEffect(() => {
    console.log("vDate:", validityDate);

    // Parse the validityDate and the current date
    const validDate = new Date(validityDate);
    const today = new Date();

    // Compare dates
    if (validDate < today) {
      console.log("plan expired");
      dispatch(
        setPlanExpiredModal({
          status: true,
          message: "Plan Expired - Please Renew",
        }),
      );
    }
  }, [validityDate]);

  useEffect(() => {
    if (planStatus === "pending") {
      dispatch(
        setUserUnverifiedModal({
          status: true,
          message: "Approval Pending",
        }),
      );
    } else if (planStatus === "approved") {
      dispatch(
        setUserUnverifiedModal({
          status: false,
          message: "",
        }),
      );
    } else if (planStatus === "declined") {
      dispatch(
        setUserUnverifiedModal({
          status: true,
          message: "Access Denied",
        }),
      );
    }
  }, [planStatus]);

  return (
    <>
      {/* List Mode */}
      {/* Top level container for posts/ */}
      {(plan === "standard" || plan === "trial") && userTypeR === "Admin" && (
        <div class="mt-2 bg-lightbgtwo dark:bg-darkbgtwo flex flex-col justify-between w-[1120px] rounded-[20px] px-5 pb-5 border border-grey">
          <table className="min-w-full table-auto my-5">
            <tr>
              <td
                colSpan="5"
                className="text-center text-2xl my-2 border-t border-b border-grey font-medium px-5 py-5"
              >
                Upgrade To Premium Plan & Above For Teams
              </td>
            </tr>
          </table>
        </div>
      )}

      {!(plan === "standard" || plan === "trial") && userTypeR === "User" && (
        <div class="mt-2 bg-lightbgtwo dark:bg-darkbgtwo flex flex-col justify-between w-[1120px] rounded-[20px] px-5 pb-5 border border-grey">
          <table className="min-w-full table-auto my-5">
            <tr>
              <td
                colSpan="5"
                className="text-center text-2xl my-2 border-t border-b border-grey font-medium px-5 py-5"
              >
                Only your admin can manage the team.
              </td>
            </tr>
          </table>
        </div>
      )}

      {!(plan === "standard" || plan === "trial") && userTypeR === "Admin" && (
        <div class="mt-2 bg-lightbgtwo dark:bg-darkbgtwo flex flex-col justify-between w-[1120px] rounded-[20px] px-5 pb-5 border border-grey">
          {/* Header */}
          <div className="flex w-full justify-between">
            <div>
              <h1 className="text-3xl font-bold my-5">Team</h1>
            </div>
            <div>
              <button
                className="flex gap-1 pb-1 w-full bg-primary text-lightbgtwo rounded-[12px] my-4 py-2 px-2 font-semibold"
                onClick={openAddUserModal}
              >
                <p className="text-2xl -mt-1.5">+</p> Add User
              </button>
            </div>
          </div>

          {/* Table Header  */}

          {/* Table Header */}
          <div className="overflow-x-auto overflow-y-auto">
            <table className="min-w-full table-auto">
              <thead>
                <tr className="text-grey dark:text-grey bg-transparent border-b border-grey">
                  <th className="px-4 py-2 text-left">Name</th>
                  <th className="px-4 py-2 text-left">Role</th>
                  <th className="px-4 py-2 text-left">Email</th>
                  <th className="px-4 py-2 text-left">Date Added</th>
                  <th className="px-4 py-2 text-right">Actions</th>
                </tr>
              </thead>
              <tbody className="">
                {allUsers !== undefined && allUsers.length > 0 ? (
                  allUsers.map((item) => (
                    <tr
                      key={item.email}
                      className="hover:cursor-pointer dark:hover:bg-lightbgone dark:hover:text-darkbgone hover:bg-darkbgone hover:text-lightbgone text-darkbgone font-semibold dark:text-lightbgone border-b border-grey"
                    >
                      <td className="px-4 py-2 text-left">{item.Name}</td>
                      <td className="px-4 py-2 text-left">{item.Role}</td>

                      <td className="px-4 py-2 text-left">{item.Email}</td>
                      <td className="px-4 py-2 text-left">
                        {item.DateCreated}
                      </td>

                      <td className="px-4 py-2">
                        <div className="flex justify-end space-x-2">
                          <button
                            className="hover:scale-110"
                            onClick={(e) => {
                              e.stopPropagation();
                              // editEntry(item);
                            }}
                          >
                            <EditIcon className="w-6 h-6 rounded-full text-darkbgone dark:text-lightbgone" />
                          </button>
                          <button
                            className="hover:scale-110"
                            onClick={(e) => {
                              e.stopPropagation();
                              // deleteEntry(item);
                            }}
                          >
                            <DeleteIcon className="w-6 h-6 rounded-full text-darkbgone dark:text-lightbgone" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="5"
                      className="text-center my-2 border-b border-grey font-medium px-5 py-5"
                    >
                      No Team Members Added At The Moment
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <AddUserModal />
      <ErrorModal />
      <UserUnverifiedModal />
      <PlanExpiredModal />
    </>
  );
};

export default Team;
