import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  getAdditionalUserInfo,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { getDatabase, ref, update, get, child } from "firebase/database";
import { setLoaderModalStatus } from "../Components/Modals/ModalSlice";
import axios from "axios";
import { ADDR } from "../config/config";

const firebaseConfig = {
  apiKey: "AIzaSyAcih11QZPRVD7y9zfS_1RdeTuvMNehFCs",
  authDomain: "metainsights-61eef.firebaseapp.com",
  databaseURL:
    "https://metainsights-61eef-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "metainsights-61eef",
  storageBucket: "metainsights-61eef.appspot.com",
  messagingSenderId: "566496973884",
  appId: "1:566496973884:web:26ed5213890385851fdf39",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const isFirstLogin = getAdditionalUserInfo(res)?.isNewUser;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        isFirstLogin: !isFirstLogin,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
    return false;
  }
};

const registerWithEmailAndPassword = async (
  displayImage,
  name,
  email,
  password,
) => {
  try {
    console.log("registering");

    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    let uid = user.uid;
    // let uid;
    let userTypeVar = "admin";
    let planTypeVar = "trial";
    let validTillVar = null;
    let status = "pending";
    await addDoc(collection(db, "users"), {
      displayImage: displayImage,
    });

    //Just storing user's dp in firebase to help with online db size

    //Instead of storing in firestore - we are storing in SQL in interest of perfomance

    //Check if the email signing up is already there in db.users (for teams).

    console.log("IN REGISTER");

    await axios
      .post(ADDR + "/checkIfEmailIsPresentInTeamUsers", { email })
      .then(async (response) => {
        // console.log(response);
        let teamUser = response.data[0];
        console.log(teamUser);

        if (teamUser !== undefined) {
          userTypeVar = "user";

          let addedByEmail = teamUser.AddedByEmail;

          await axios
            .post(ADDR + "/getLicenseTypeByAdminEmail", { addedByEmail })
            .then((response) => {
              let data = response.data;
              console.log(data);
              planTypeVar = data.planType;
              validTillVar = data.validTill;
              // uid = data.userID;
              status = "approved";
            });
        }
      });

    let reply = await axios
      .post(ADDR + "/addUserProfileData", {
        uid,
        name,
        authProvider: "local",
        email,
        isFirstLogin: true,
        userType: userTypeVar,
        status: status,
        projects: [],
      })
      .then(async (response) => {
        if (response.status !== 200) {
          console.log("Something wrong");
        } else if (response.status === 200) {
          console.log("Success!");
          //Payment info SS will be checked and deleted - so keeping in DB at the moment.
          let reply = await axios
            .post(ADDR + "/storePaymentInfo", {
              image: "",
              planType: planTypeVar,
              price: 0,
              userID: uid,
              userEmail: email,
              validTill: validTillVar,
              status: status,
            })
            .then((response) => {
              console.log(response);
              return true;
            })
            .catch((error) => {
              console.log(error);
              return false;
            });

          return reply;
        }
        console.log(reply);
      });
    return true;
  } catch (err) {
    console.error(err);
    alert(err.message);
    return false;
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

const checkFirstLogin = async (uid) => {
  if (uid) {
    let dataRef = query(collection(db, "users"), where("uid", "==", uid));
    const datatempd = await getDocs(dataRef);
    let arr = [];
    datatempd.forEach((doc) => {
      arr.push(doc.get("isFirstLogin"));
    });
    return arr[0];
  }
};

const getDocumentID = async (uid) => {
  if (uid) {
    let dataRef = query(collection(db, "users"), where("uid", "==", uid));
    const dataTemp = await getDocs(dataRef);
    let arr = [];
    dataTemp.forEach((doc) => {
      arr.push(doc.id);
    });
    return arr[0];
  }
};

const updateProject = async (projectName, updatedData) => {
  const db = getDatabase();
  const dbRef = ref(db);

  // Find the project by projectName
  const snapshot = await get(child(dbRef, `projects`));
  if (snapshot.exists()) {
    const projects = snapshot.val();
    const projectKey = Object.keys(projects).find(
      (key) => projects[key].projectName === projectName,
    );

    if (projectKey) {
      // Update the project
      await update(ref(db, `projects/${projectKey}`), updatedData);
      console.log("Project updated successfully");
    } else {
      console.log("Project not found");
    }
  } else {
    console.log("No data available");
  }
};

const getDP = async (uid) => {
  if (uid) {
    let dataRef = query(collection(db, "users"), where("uid", "==", uid));
    const dataTemp = await getDocs(dataRef);
    let arr = [];

    dataTemp.forEach((doc) => {
      arr.push(doc.get("displayImage"));
    });

    console.log("ARR0DP", arr[0]);
    return arr[0];
  }
};

const getName = async (uid) => {
  if (uid) {
    let dataRef = query(collection(db, "users"), where("uid", "==", uid));
    const dataTemp = await getDocs(dataRef);
    let arr = [];

    dataTemp.forEach((doc) => {
      arr.push(doc.get("name"));
    });

    console.log("ARR0DP", arr[0]);
    return arr[0];
  }
};

const getExistingProjects = async (uid) => {
  if (uid) {
    let dataRef = query(collection(db, "users"), where("uid", "==", uid));
    const dataTemp = await getDocs(dataRef);
    let arr = [];
    dataTemp.forEach((doc) => {
      arr.push(doc.get("projects"));
    });
    return arr[0];
  }
};

// const deleteProjectEntry = async (uid,selectedProject) =>{
//   let response = await getExistingProjects(uid);
//   console.log('existing projects', response)
//   // Use the filter method to remove the matching element
// const updatedProjects = response.filter(project =>
//   project.projectName !== selectedProject.projectName ||
//   project.lastUsed !== selectedProject.lastUsed ||
//   project.createdDate !== selectedProject.createdDate ||
//   project.lastModified !== selectedProject.lastModified ||
//   JSON.stringify(project.usernameList) !== JSON.stringify(selectedProject.usernameList)
// );

// console.log(updatedProjects);

// }

const getExistingForDeleteProjects = async (uid) => {
  if (uid) {
    let dataRef = query(collection(db, "users"), where("uid", "==", uid));
    const dataTemp = await getDocs(dataRef);
    let docId = "";
    let arr = [];
    dataTemp.forEach((doc) => {
      arr.push(doc.get("projects"));
      docId = doc.id; // Store the document ID for later use
    });
    return { projects: arr[0], docId };
  }
};

// Function to delete a specific project entry
const deleteProjectEntry = async (uid, selectedProject) => {
  const { projects, docId } = await getExistingForDeleteProjects(uid);

  if (projects) {
    console.log("existing projects", projects);

    // Filter out the project that matches the selectedProject
    const updatedProjects = projects.filter(
      (project) =>
        project.lastUsed !== selectedProject.lastUsed ||
        project.projectName !== selectedProject.projectName ||
        project.createdDate !== selectedProject.createdDate ||
        project.lastModified !== selectedProject.lastModified ||
        JSON.stringify(project.usernameList) !==
          JSON.stringify(selectedProject.usernameList),
    );

    if (docId) {
      // Update the document with the new array
      const docRef = doc(db, "users", docId);
      await updateDoc(docRef, {
        projects: updatedProjects,
      });

      console.log("Project entry deleted successfully!");
    } else {
      console.log("Document ID not found!");
    }
  } else {
    console.log("No projects found!");
  }
};

const getUserNiches = async (uid) => {
  if (uid) {
    let dataRef = query(collection(db, "users"), where("uid", "==", uid));
    const dataTemp = await getDocs(dataRef);
    let arr = [];
    dataTemp.forEach((doc) => {
      arr.push(doc.get("userNiches"));
    });
    return arr[0];
  }
};

const addUserNiches = async (uid, niches) => {
  if (uid) {
    try {
      let documentID = await getDocumentID(uid);
      let dataRef = doc(db, "users", documentID);

      const data = {
        isFirstLogin: false,
        userNiches: niches,
      };
      await updateDoc(dataRef, data);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
};

const addUserProject = async (
  uid,
  projectName,
  createdDate,
  lastModified,
  lastUsed,
  usernameList,
) => {
  if (uid) {
    try {
      // Get the document ID for the user
      let documentID = await getDocumentID(uid);
      let dataRef = doc(db, "users", documentID);

      // Fetch the user's existing projects
      let existingProjects = await getExistingProjects(uid);

      // Ensure existingProjects is an array
      if (!Array.isArray(existingProjects)) {
        existingProjects = [];
      }

      // Add the new project to the existing projects array
      existingProjects.push({
        projectName: projectName,
        usernameList: usernameList,
        lastModified: lastModified,
        lastUsed: lastUsed,
        createdDate: createdDate,
      });

      // Prepare the data object to update Firestore
      const data = {
        projects: existingProjects,
      };

      // Update the user's document in Firestore
      await updateDoc(dataRef, data);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
};

// const addUserProject = async (uid, projectName,createdDate,lastModified, usernameList) => {
//   if (uid) {
//     try{
//       let existingProjects = []
//       let documentID = await getDocumentID(uid);
//       let dataRef = doc(db,"users",documentID);
//       let existingProjectss = await getExistingProjects(uid);
//       console.log(existingProjectss);
//       if(existingProjectss.length===0){
//         existingProjects.push({
//           projectName:projectName,
//           usernameList:usernameList,
//           lastModified:lastModified,
//           createdDate:createdDate,
//         })
//       }else if(existingProjectss.length>0){
//         existingProjects.push([existingProjectss])
//         console.log(existingProjects)
//         existingProjects.push({
//           projectName:projectName,
//           usernameList:usernameList,
//           lastModified:lastModified,
//           createdDate:createdDate,
//         })
//       }

//       const data = {
//         projects:existingProjects
//       };
//         await updateDoc(dataRef, data);
//         return true;
//       }
//     catch(e){
//       console.log(e);
//       return false;
//     }
//   }
// };

export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  checkFirstLogin,
  addUserNiches,
  getUserNiches,
  addUserProject,
  getExistingProjects,
  updateProject,
  deleteProjectEntry,
  getDP,
  getName,
};
