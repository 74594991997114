import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ReactComponent as ListCheck } from "../../../assets/v2/ListTick.svg";
import { setErrorModalStatus, setPaymentModal } from "../../Modals/ModalSlice";
import PaymentModal from "../../Modals/PaymentModal";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../app/firebase";
import axios from "axios";
import { ADDR } from "../../../config/config";
import { ErrorModal } from "../../Modals/ErrorModal";

const PlansAndBilling = () => {
  const dispatch = useAppDispatch();
  const [user, loading] = useAuthState(auth);
  let [plan, setPlan] = useState();
  let [validityDate, setValidityDate] = useState();
  let [planStatus, setPlanStatus] = useState();

  let mediaData = useAppSelector((state) => state.homeSlice.mediaData);

  let fullName = useAppSelector((state) => state.homeSlice.nameOfUser.value);

  let userTypeR = useAppSelector((state) => state.homeSlice.userType.value);

  let selectedCSS =
    "w-full bg-lightbgtwo dark:bg-transparent border border-lightbgthree dark:border-darkbgfour rounded-[16px] py-1 font-semibold";
  let unSelectedCSS =
    "w-full bg-primary text-lightbgtwo rounded-[16px] py-1 font-semibold";

  let selectedMessage = "Active";
  let unSelectedMessage = "Upgrade";

  let [buttonOneCSS, setButtonOneCSS] = useState(unSelectedCSS);
  let [buttonTwoCSS, setButtonTwoCSS] = useState(unSelectedCSS);
  let [buttonThreeCSS, setButtonThreeCSS] = useState(unSelectedCSS);

  let [buttonOneMessage, setButtonOneMessage] = useState(unSelectedMessage);
  let [buttonTwoMessage, setButtonTwoMessage] = useState(unSelectedMessage);
  let [buttonThreeMessage, setButtonThreeMessage] = useState(unSelectedMessage);

  const getLicenseDetails = async (email, userID) => {
    console.log(email);
    console.log(userID);
    let response = await axios
      .post(ADDR + "/getLicenseDataOfUser", { email, userID })
      .then((response) => {
        return response;
      });

    console.log("RES: ", response);
    let data = response.data;
    console.log(data);

    const latest = data.reduce((latestObj, currentObj) => {
      return new Date(currentObj.currentDate) > new Date(latestObj.currentDate)
        ? currentObj
        : latestObj;
    });

    console.log("Latest:", latest);

    setPlan(latest.planType);
    setValidityDate(latest.validTill);
    let status = capitalizeFirstLetter(latest.status);
    setPlanStatus(status);
  };

  const formatDateToText = (date) => {
    console.log("DATE:", date);
    if (!date) {
      //   console.error("Invalid date: The input is undefined or null");
      return "No date";
    }

    const dateVar = new Date(date);

    if (isNaN(dateVar)) {
      console.error("Invalid date: Unable to parse the input");
      return "No date";
    }

    const options = { day: "2-digit", month: "long", year: "numeric" };
    return new Intl.DateTimeFormat("en-GB", options).format(dateVar);
  };

  const capitalizeFirstLetter = (plan) => {
    if (plan !== undefined) {
      let text = plan.charAt(0).toUpperCase() + plan.slice(1);
      return text;
    } else {
      return plan;
    }
  };

  useEffect(() => {
    if (plan === "standard") {
      setButtonOneCSS(selectedCSS);
      setButtonOneMessage(selectedMessage);
      setButtonTwoCSS(unSelectedCSS);
      setButtonTwoMessage(unSelectedMessage);
      setButtonThreeCSS(unSelectedCSS);
      setButtonThreeMessage(unSelectedMessage);
    } else if (plan === "premium") {
      setButtonOneCSS(unSelectedCSS);
      setButtonOneMessage(unSelectedMessage);
      setButtonTwoCSS(selectedCSS);
      setButtonTwoMessage(selectedMessage);
      setButtonThreeCSS(unSelectedCSS);
      setButtonThreeMessage(unSelectedMessage);
    } else if (plan === "enterprise") {
      setButtonOneCSS(unSelectedCSS);
      setButtonOneMessage(unSelectedMessage);
      setButtonTwoCSS(unSelectedCSS);
      setButtonTwoMessage(unSelectedMessage);
      setButtonThreeCSS(selectedCSS);
      setButtonThreeMessage(selectedMessage);
    }
  }, [plan]);

  useEffect(() => {
    if (user) {
      getLicenseDetails(user.email, user.uid);
    } else {
      console.log("Not logged in");
      //   window.location.replace("/login");
    }
  }, [user]);

  return (
    <div className="flex">
      <div className="flex gap-2 justify-between w-full mt-2">
        {/* Payment Method Card */}

        {(userTypeR === "Admin" || userTypeR === "User") && (
          <div className="bg-lightbgtwo dark:bg-darkbgtwo border border-grey w-1/4 flex flex-col justify-between rounded-[12px]">
            <div className="mx-5 mt-4">
              <h1 className="text-left text-2xl font-semibold">
                Payment Method
              </h1>
              <p className="text-left text-sm text-grey">
                Change how you pay for your plan
              </p>
            </div>

            <div className="mx-5">
              <h1 className="text-left text-xl font-semibold">
                {capitalizeFirstLetter(plan)} Plan
              </h1>
              <h1 className="text-left text-2xl">
                {formatDateToText(validityDate) || "No Date"}
              </h1>

              <p className="text-left text-grey text-sm">Next Payment</p>
            </div>

            <div className="flex justify-between bg-lightbgone dark:bg-darkbgthree rounded-[12px] mx-5 mb-4">
              <div className="px-2 py-2">
                <h1 className="text-left font-semibold text-md">{fullName}</h1>

                <p className="text-left text-sm">{planStatus}</p>
              </div>
              <div className="flex items-center px-2">
                {/* <button className="bg-lightbgthree dark:bg-darkbgfour rounded-[12px] w-[55px] h-[35px]">
                Info
              </button> */}
              </div>
            </div>
          </div>
        )}
        {userTypeR === "Admin" && (
          <div className="bg-lightbgtwo dark:bg-darkbgtwo border border-grey w-3/4 flex flex-col gap-4 rounded-[12px]">
            <div className="mx-5 mt-4">
              {" "}
              <h1 className="text-left text-2xl font-semibold">Plans</h1>
            </div>

            <div className="flex mx-5 mb-2 gap-2">
              <div className="flex flex-col dark:bg-darkbgthree bg-lightbgone border dark:border-darkbgfour rounded-[12px]">
                <div className="flex flex-col gap-2 mx-5 my-2">
                  <div className="flex gap-2">
                    <p className="text-left font-semibold text-md">
                      Standard Plan
                    </p>

                    <div className="flex pt-2 pl-4">
                      <p className="text-2xl">24€</p>

                      <p className="text-grey text-sm">/month</p>
                    </div>
                  </div>

                  <div className="flex flex-row">
                    <div className="text-left text-sm flex flex-col gap-1">
                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mx-5 my-2">
                  <button
                    onClick={() => {
                      dispatch(
                        setPaymentModal({
                          status: true,
                          plan: "standard",
                        }),
                      );
                    }}
                    className={buttonOneCSS}
                  >
                    {buttonOneMessage}
                  </button>
                </div>
              </div>

              <div className="flex flex-col dark:bg-darkbgthree bg-lightbgone border dark:border-darkbgfour rounded-[12px]">
                <div className="flex flex-col gap-2 mx-5 my-2">
                  <div className="flex gap-2">
                    <p className="text-left font-semibold text-md">
                      Premium Plan
                    </p>

                    <div className="flex pt-2 pl-4">
                      <p className="text-2xl">46€</p>

                      <p className="text-grey text-sm">/month</p>
                    </div>
                  </div>

                  <div className="flex flex-row">
                    <div className="text-left text-sm flex flex-col gap-1">
                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mx-5 my-2">
                  <button
                    onClick={() => {
                      dispatch(
                        setPaymentModal({
                          status: true,
                          plan: "premium",
                        }),
                      );
                    }}
                    className={buttonTwoCSS}
                  >
                    {buttonTwoMessage}
                  </button>
                </div>
              </div>

              <div className="flex flex-col dark:bg-darkbgthree bg-lightbgone border dark:border-darkbgfour rounded-[12px]">
                <div className="flex flex-col gap-2 mx-5 my-2">
                  <div className="flex gap-2">
                    <p className="text-left font-semibold text-md">
                      Enterprise Plan
                    </p>

                    <div className="flex pt-2 pl-4">
                      <p className="text-2xl">79€</p>

                      <p className="text-grey text-sm">/month</p>
                    </div>
                  </div>

                  <div className="flex flex-row">
                    <div className="text-left text-sm flex flex-col gap-1">
                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mx-5 my-2">
                  <button
                    onClick={() => {
                      dispatch(
                        setPaymentModal({
                          status: true,
                          plan: "enterprise",
                        }),
                      );
                    }}
                    className={buttonThreeCSS}
                  >
                    {buttonThreeMessage}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {userTypeR === "User" && (
          <div className="bg-lightbgtwo dark:bg-darkbgtwo border border-grey w-3/4 flex flex-col gap-4 rounded-[12px]">
            <div className="mx-5 mt-4">
              {" "}
              <h1 className="text-left text-2xl font-semibold">Plans</h1>
            </div>

            <div className="flex mx-5 mb-2 gap-2">
              <div className="flex flex-col dark:bg-darkbgthree bg-lightbgone border dark:border-darkbgfour rounded-[12px]">
                <div className="flex flex-col gap-2 mx-5 my-2">
                  <div className="flex gap-2">
                    <p className="text-left font-semibold text-md">
                      Standard Plan
                    </p>

                    <div className="flex pt-2 pl-4">
                      <p className="text-2xl">24€</p>

                      <p className="text-grey text-sm">/month</p>
                    </div>
                  </div>

                  <div className="flex flex-row">
                    <div className="text-left text-sm flex flex-col gap-1">
                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mx-5 my-2">
                  <button
                    onClick={() => {
                      dispatch(
                        setErrorModalStatus({
                          status: true,
                          message: "Only Admin Can Upgrade",
                        }),
                      );
                    }}
                    className={buttonOneCSS}
                  >
                    {buttonOneMessage}
                  </button>
                </div>
              </div>

              <div className="flex flex-col dark:bg-darkbgthree bg-lightbgone border dark:border-darkbgfour rounded-[12px]">
                <div className="flex flex-col gap-2 mx-5 my-2">
                  <div className="flex gap-2">
                    <p className="text-left font-semibold text-md">
                      Premium Plan
                    </p>

                    <div className="flex pt-2 pl-4">
                      <p className="text-2xl">46€</p>

                      <p className="text-grey text-sm">/month</p>
                    </div>
                  </div>

                  <div className="flex flex-row">
                    <div className="text-left text-sm flex flex-col gap-1">
                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mx-5 my-2">
                  <button
                    onClick={() => {
                      dispatch(
                        setErrorModalStatus({
                          status: true,
                          message: "Only Admin Can Upgrade",
                        }),
                      );
                    }}
                    className={buttonTwoCSS}
                  >
                    {buttonTwoMessage}
                  </button>
                </div>
              </div>

              <div className="flex flex-col dark:bg-darkbgthree bg-lightbgone border dark:border-darkbgfour rounded-[12px]">
                <div className="flex flex-col gap-2 mx-5 my-2">
                  <div className="flex gap-2">
                    <p className="text-left font-semibold text-md">
                      Enterprise Plan
                    </p>

                    <div className="flex pt-2 pl-4">
                      <p className="text-2xl">79€</p>

                      <p className="text-grey text-sm">/month</p>
                    </div>
                  </div>

                  <div className="flex flex-row">
                    <div className="text-left text-sm flex flex-col gap-1">
                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>

                      <div className="flex gap-1">
                        <ListCheck />
                        <p>Feature One</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mx-5 my-2">
                  <button
                    onClick={() => {
                      dispatch(
                        setErrorModalStatus({
                          status: true,
                          message: "Only Admin Can Upgrade",
                        }),
                      );
                    }}
                    className={buttonThreeCSS}
                  >
                    {buttonThreeMessage}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <PaymentModal />
      <ErrorModal />
    </div>
  );
};

export default PlansAndBilling;
