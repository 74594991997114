import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { setUserDisplayImage } from "../../Modals/ModalSlice";
import imageCompression from "browser-image-compression";

const ImagePicker = () => {
  const dispatch = useAppDispatch();

  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        setIsLoading(true);

        // Compress the image
        const options = {
          maxSizeMB: 1, // Compress to 1 MB max
          maxWidthOrHeight: 1920, // Resize max dimensions
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);

        // Convert compressed image to base64
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedImage(reader.result); // Set the image as a data URL
          setIsLoading(false);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Error compressing image:", error);
        setIsLoading(false);
      }
    }
  };

  const removeImage = () => {
    setSelectedImage(null); // Clear the selected image
  };

  useEffect(() => {
    dispatch(
      setUserDisplayImage({
        image: selectedImage,
      }),
    );
  }, [selectedImage, dispatch]);

  return (
    <div className="flex flex-row justify-between items-center gap-4 my-2">
      {/* Placeholder or Image Preview */}
      <div className="relative w-[200px] h-[200px] rounded-[12px] border-2 border-dashed border-grey flex items-center justify-center bg-lightbgthree dark:bg-darkbgthree shadow-md">
        {isLoading ? (
          <p className="text-grey text-sm">Loading...</p>
        ) : selectedImage ? (
          <>
            <img
              src={selectedImage}
              alt="Selected Preview"
              className="w-[200px] h-[200px] object-cover rounded-[12px]"
            />
            {/* Remove Button */}
            <button
              onClick={removeImage}
              className="absolute top-2 right-2 bg-red-500 text-lightbgtwo px-3 py-1 rounded-full shadow-md hover:bg-red-700"
            >
              ✕
            </button>
          </>
        ) : (
          <p className="text-grey text-sm">Select Profile Picture</p>
        )}
      </div>

      {/* Image Upload Input */}
      <div className="flex justify-center mr-5">
        <label
          htmlFor="imageInput"
          className="w-full cursor-pointer my-2 text-lightbgtwo bg-primary font-semibold rounded-[20px] text-lg px-5 py-4 text-center mr-3 md:mr-0 flex justify-center items-center"
        >
          Choose Image
        </label>
        <input
          id="imageInput"
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="hidden"
        />
      </div>
    </div>
  );
};

export default ImagePicker;
